import React, { useState } from 'react'
import { Link } from 'gatsby'
import Logo from '../components/logo'
const Header = () => {
    const [isOpen, setOpen] = useState(false)
    return (
        <header className="bg-primary text-gray-200">
            <nav className="container mx-auto px-2 xl:px-0">
                <div className="flex justify-between items-center">
                    <div className="text-3xl tracking-widest">
                        <Link to="/" className="h-24 flex items-center">
                            <Logo />
                        </Link>
                    </div>
                    <div className="flex items-center">
                        <Link
                            to="/#search-input"
                            className="text-white flex items-center p-4 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:ring focus:ring-accent"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                className="fill-current h-5 w-5"
                            >
                                <path d="M23.111 20.058l-4.977-4.977c.965-1.52 1.523-3.322 1.523-5.251 0-5.42-4.409-9.83-9.829-9.83-5.42 0-9.828 4.41-9.828 9.83s4.408 9.83 9.829 9.83c1.834 0 3.552-.505 5.022-1.383l5.021 5.021c2.144 2.141 5.384-1.096 3.239-3.24zm-20.064-10.228c0-3.739 3.043-6.782 6.782-6.782s6.782 3.042 6.782 6.782-3.043 6.782-6.782 6.782-6.782-3.043-6.782-6.782zm2.01-1.764c1.984-4.599 8.664-4.066 9.922.749-2.534-2.974-6.993-3.294-9.922-.749z" />
                            </svg>
                        </Link>
                        <button
                            id="menu-btn"
                            className="text-white flex items-center p-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:ring focus:ring-accent"
                            onClick={(e) => toggleMenu(e, 400, setOpen)}
                        >
                            {isOpen ? (
                                <svg
                                    aria-labelledby="closeTitle"
                                    role="img"
                                    className="h-8 w-8 p-2 fill-current"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <title id="closeTitle">Close Menu</title>
                                    <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
                                </svg>
                            ) : (
                                <svg
                                    aria-labelledby="menuTitle"
                                    role="img"
                                    className="h-8 w-8 fill-current"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <title id="menuTitle">Open Menu</title>
                                    <path d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z"></path>
                                </svg>
                            )}
                        </button>
                    </div>
                </div>
                <div
                    id="menu-div"
                    className="hidden text-sm md:text-base lg:text-lg xl:text-xl"
                >
                    <Link
                        to="/undergraduate/supplement_2017-2018"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        Undergraduate Bulletin Supplement 2017-2018
                    </Link>
                    <Link
                        to="/graduate/2017-2018"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        Graduate Bulletin 2017-2018
                    </Link>
                    <Link
                        to="/undergraduate/2017-2018"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        Undergraduate Bulletin 2017-2018
                    </Link>
                    <Link
                        to="/undergraduate/supplement_2016-2017"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        Undergraduate Bulletin Supplement 2016-2017
                    </Link>
                    <Link
                        to="/graduate/2016-2017"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        Graduate Bulletin 2016-2017
                    </Link>
                    <Link
                        to="/undergraduate/2016-2017"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        Undergraduate Bulletin 2016-2017
                    </Link>
                    <Link
                        to="/undergraduate/supplement_2015-2016"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        Undergraduate Bulletin Supplement 2015-2016
                    </Link>
                    <Link
                        to="/graduate/2015-2016"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        Graduate Bulletin 2015-2016
                    </Link>
                    <Link
                        to="/undergraduate/2015-2016"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        Undergraduate Bulletin 2015-2016
                    </Link>
                    <Link
                        to="/undergraduate/supplement_2014-2015"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        Undergraduate Bulletin Supplement 2014-2015
                    </Link>
                    <Link
                        to="/graduate/2014-2015"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        Graduate Bulletin 2014-2015
                    </Link>
                    <Link
                        to="/undergraduate/2014-2015"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        Undergraduate Bulletin 2014-2015
                    </Link>
                    <Link
                        to="/graduate/supplement_2013-2014"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        Graduate Bulletin Supplement 2013-2014
                    </Link>
                    <Link
                        to="/undergraduate/supplement_2013-2014"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        Undergraduate Bulletin Supplement 2013-2014
                    </Link>
                    <Link
                        to="/graduate/2013-2014"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        Graduate Bulletin 2013-2014
                    </Link>
                    <Link
                        to="/undergraduate/2013-2014"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        Undergraduate Bulletin 2013-2014
                    </Link>
                    <Link
                        to="/graduate/2012-2013"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        Graduate Bulletin 2012-2013
                    </Link>
                    <Link
                        to="/undergraduate/2012-2013"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        Undergraduate Bulletin 2012-2013
                    </Link>
                    <Link
                        to="/graduate/2011-2012"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        Graduate Bulletin 2011-2012
                    </Link>
                    <Link
                        to="/undergraduate/2011-2012"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        Undergraduate Bulletin 2011-2012
                    </Link>
                    <Link
                        to="/graduate/2010-2011"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        Graduate Bulletin 2010-2011
                    </Link>
                    <Link
                        to="/undergraduate/2010-2011"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        Undergraduate Bulletin 2010-2011
                    </Link>
                    <a
                        href="/pdfs/Graduate-Bulletin-2009-2010.pdf"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Graduate Bulletin 2009-2010
                    </a>
                    <a
                        href="/pdfs/Undergraduate-Bulletin-2009-2010.pdf"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Undergraduate Bulletin 2009-2010
                    </a>
                    <a
                        href="/pdfs/Graduate-Bulletin-2008-2009.pdf"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Graduate Bulletin 2008-2009
                    </a>
                    <a
                        href="/pdfs/Undergraduate-Bulletin-2008-2009.pdf"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Undergraduate Bulletin 2008-2009
                    </a>
                    <a
                        href="/pdfs/Graduate-Bulletin-2007-2008.pdf"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Graduate Bulletin 2007-2008
                    </a>
                    <a
                        href="/pdfs/Undergraduate-Bulletin-2007-2008.pdf"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Undergraduate Bulletin 2007-2008
                    </a>
                    <a
                        href="/pdfs/Graduate-Bulletin-2006-2007.pdf"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Graduate Bulletin 2006-2007
                    </a>
                    <a
                        href="/pdfs/Undergraduate-Bulletin-2006-2007.pdf"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Undergraduate Bulletin 2006-2007
                    </a>
                    <a
                        href="/pdfs/Graduate-Bulletin-2005-2006.pdf"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Graduate Bulletin 2005-2006
                    </a>
                    <a
                        href="/pdfs/Undergraduate-Bulletin-2005-2006.pdf"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Undergraduate Bulletin 2005-2006
                    </a>
                    <a
                        href="/pdfs/Graduate-Bulletin-2004-2005.pdf"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Graduate Bulletin 2004-2005
                    </a>
                    <a
                        href="/pdfs/Undergraduate-Bulletin-2004-2005.pdf"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Undergraduate Bulletin 2004-2005
                    </a>
                    <a
                        href="/pdfs/Graduate-Bulletin-2003-2004.pdf"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Graduate Bulletin 2003-2004
                    </a>
                    <a
                        href="/pdfs/Undergraduate-Bulletin-2003-2004.pdf"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Undergraduate Bulletin 2003-2004
                    </a>
                    <a
                        href="/pdfs/Graduate-Bulletin-2002-2003.pdf"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Graduate Bulletin 2002-2003
                    </a>
                    <a
                        href="/pdfs/Undergraduate-Bulletin-2002-2003.pdf"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Undergraduate Bulletin 2002-2003
                    </a>
                    <a
                        href="/pdfs/Graduate-Bulletin-2001-2002.pdf"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Graduate Bulletin 2001-2002
                    </a>
                    <a
                        href="/pdfs/Undergraduate-Bulletin-2001-2002.pdf"
                        className="py-2 transition-colors duration-300 ease-in-out hover:text-accent focus:outline-none focus:text-accent"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Undergraduate Bulletin 2001-2002
                    </a>
                </div>
            </nav>
        </header>
    )
}

export default Header

const slideUp = (target, duration = 400, setOpen) => {
    target.style.transitionProperty = 'height, margin, padding'
    target.style.transitionDuration = duration + 'ms'
    target.style.boxSizing = 'border-box'
    target.style.height = target.offsetHeight + 'px'
    target.offsetHeight
    target.style.overflow = 'hidden'
    target.style.height = 0
    target.style.paddingTop = 0
    target.style.paddingBottom = 0
    target.style.marginTop = 0
    target.style.marginBottom = 0
    window.setTimeout(() => {
        target.classList.add('hidden')
        target.classList.remove('grid')

        target.style.removeProperty('height')
        target.style.removeProperty('padding-top')
        target.style.removeProperty('padding-bottom')
        target.style.removeProperty('margin-top')
        target.style.removeProperty('margin-bottom')
        target.style.removeProperty('overflow')
        target.style.removeProperty('transition-duration')
        target.style.removeProperty('transition-property')
        //alert("!");
        setOpen(false)
    }, duration)
}

const slideDown = (target, duration = 400, setOpen) => {
    target.style.removeProperty('display')
    target.classList.add('grid')
    target.classList.remove('hidden')
    let height = target.offsetHeight
    target.style.overflow = 'hidden'
    target.style.height = 0
    target.style.paddingTop = 0
    target.style.paddingBottom = 0
    target.style.marginTop = 0
    target.style.marginBottom = 0
    target.offsetHeight
    target.style.boxSizing = 'border-box'
    target.style.transitionProperty = 'height, margin, padding'
    target.style.transitionDuration = duration + 'ms'
    target.style.height = height + 'px'
    target.style.removeProperty('padding-top')
    target.style.removeProperty('padding-bottom')
    target.style.removeProperty('margin-top')
    target.style.removeProperty('margin-bottom')
    window.setTimeout(() => {
        target.style.removeProperty('height')
        target.style.removeProperty('overflow')
        target.style.removeProperty('transition-duration')
        target.style.removeProperty('transition-property')
        setOpen(true)
    }, duration)
}

const toggleMenu = (e, duration = 1000, setOpen) => {
    e.preventDefault()
    const target = document.getElementById('menu-div')
    if (window.getComputedStyle(target, duration).display === 'none') {
        return slideDown(target, duration, setOpen)
    }
    return slideUp(target, duration, setOpen)
}
