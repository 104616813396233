import React from 'react'
import { Link } from 'gatsby'
import FooterLogo from '../components/footer-logo'

const Footer = () => {
    return (
        <footer>
            {/* <section className="bg-dark text-gray-100">
                <div className="container mx-auto pl-1 pt-1 pr-1 pb-2 relative">
                    <input
                        id="search-input"
                        type="text"
                        className="w-full bg-dark border-none py-4 pl-14 text-3xl tracking-wider font-semibold focus:outline-none focus:ring-1 focus:ring-accent"
                        placeholder="Search SWAU"
                    />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="fill-current text-gray-400 h-10 w-10 absolute top-4 left-3"
                    >
                        <path d="M23.111 20.058l-4.977-4.977c.965-1.52 1.523-3.322 1.523-5.251 0-5.42-4.409-9.83-9.829-9.83-5.42 0-9.828 4.41-9.828 9.83s4.408 9.83 9.829 9.83c1.834 0 3.552-.505 5.022-1.383l5.021 5.021c2.144 2.141 5.384-1.096 3.239-3.24zm-20.064-10.228c0-3.739 3.043-6.782 6.782-6.782s6.782 3.042 6.782 6.782-3.043 6.782-6.782 6.782-6.782-3.043-6.782-6.782zm2.01-1.764c1.984-4.599 8.664-4.066 9.922.749-2.534-2.974-6.993-3.294-9.922-.749z" />
                    </svg>
                </div>
            </section> */}
            <section className="bg-primary text-gray-100">
                <div className="container mx-auto px-2 py-8 grid gap-4 justify-center items-center text-center text-sm md:text-lg md:tracking-wider">
                    <div>
                        <Link to="/">
                            <FooterLogo />
                        </Link>
                        <div className="pt-2">
                            &copy;{new Date().getFullYear()}{' '}
                            <a
                                href="https://swau.edu"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Southwestern Adventist University
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    )
}

export default Footer
