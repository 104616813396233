import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const FooterLogo = () => {
    return (
        <StaticImage
            src="../images/footer-logo.png"
            alt="Southwestern Adventist University Footer Logo"
            placeholder="tracedSVG"
            width="192"
            height="157"
        />
    )
}

export default FooterLogo
