import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const FooterLogo = () => {
    return (
        <StaticImage
            src="../images/logo.png"
            alt="Southwestern Adventist University Logo"
            loading="eager"
            placeholder="tracedSVG"
            height="57"
            width="200"
        />
    )
}

export default FooterLogo
